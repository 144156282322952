.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
}

form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
}

.form button{
    height: 36px;
    border-radius: 4px; 
    background: linear-gradient(45deg, #6A7FDB, #57E2E5, #45CB85);
    background-size: 1000% 1000%;
    animation: colors 20s ease infinite;
    border: 0;
    font-size: 18px;
    color: #fff;
}

@keyframes colors {
  0%{
    background-position: 0% 50%;
  }
  50%{
    background-position: 100% 50%;
  }
  100%{
    background-position: 0% 50%;
  }
}

.button:hover {
    transform: scale(1.04);
}