.logo{
    margin-top: 44px;
    margin-bottom: 28px;
    color: #fff;
    font-size: 54px;
}

.logo-text{
    background: linear-gradient(45deg, #6A7FDB, #57E2E5, #45CB85);
    background-clip: text;
    background-size: 1000% 1000%;
    animation: colors 20s ease infinite;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@keyframes colors {
  0%{
    background-position: 0% 50%;
  }
  50%{
    background-position: 100% 50%;
  }
  100%{
    background-position: 0% 50%;
  }
}